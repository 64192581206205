import pluralize from 'pluralize';
import { useCallback, useState } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type OrgConnection } from '../../../types';
import { err2s } from '../../../utils/common';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../../Loading';

function DeletedUsersPreview(props: {
  previewEmails: string[];
  totalCount: number;
}) {
  if (!props.previewEmails.length) return null;

  const othersCount = props.totalCount - props.previewEmails.length;
  const previews = props.previewEmails.map((email) => (
    <li key={email} className='font-bold inline'>
      {email}
    </li>
  ));

  return (
    <>
      , including{' '}
      {previews.map((p, index) => {
        if (index === previews.length - 1) return p;
        if (index === previews.length - 2) return <>{p} and </>;
        return <>{p}, </>;
      })}
      {othersCount > 0 &&
        ` and ${othersCount} ${pluralize('other', othersCount)}`}
    </>
  );
}

function OpenAsSourceOfTruthModal(props: {
  connection: OrgConnection;
  onComplete: (sendInviteEmails: boolean) => void;
  onClose: () => void;
}): JSX.Element {
  const [sendInviteEmails, setSendInviteEmails] = useState(false);

  const { data, error } = useSWR(
    `/organization/${props.connection.orgId}/connection/as-source-of-truth/check`,
    async () => {
      const resp =
        await apiService.organization.checkExConnectionAsSourceOfTruth(
          props.connection.orgId
        );
      return resp.data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  if (error) {
    return (
      <ModalWrapper
        onClose={props.onClose}
        borderStyle='gray'
        containerClassName='w-172'
      >
        <div className='w-full min-h-40 px-12.5 py-10 text-red-002 flex justify-center items-center text-center'>
          oops! Something wrong! <br />
          Err: {err2s(error)}
        </div>
      </ModalWrapper>
    );
  }
  if (!data) return <Loading />;
  return (
    <ModalWrapper
      onClose={props.onClose}
      borderStyle='gray'
      containerClassName='w-172'
    >
      <div className='w-full px-12.5 py-10'>
        <header className='w-full text-center text-2xl font-medium'>
          ✋ Carefully review and confirm
        </header>

        <main className='w-full mt-10 text-sms font-normal'>
          <section className='w-full flex flex-col gap-4'>
            <p>
              There are{' '}
              <span className='text-tertiary'>
                {data.exUsersCount.toLocaleString()} People
              </span>{' '}
              in the slack work space{' '}
              <span className='text-tertiary'>{props.connection.name}</span>.
            </p>
            <p>
              This action will start an asynchronous task to{' '}
              <span className='text-green-001'>
                create {data.addUsersCount.toLocaleString()} new{' '}
                {pluralize('account', data.addUsersCount)}
              </span>
              .
            </p>
            <p>
              This action will also{' '}
              <span className='text-red-002'>
                {`delete ${data.deleteUsersCount.toLocaleString()} existing ${pluralize(
                  'account',
                  data.deleteUsersCount
                )}`}
              </span>{' '}
              that don’t have a match
              <DeletedUsersPreview
                previewEmails={data.deleteUserEmailsPreview}
                totalCount={data.deleteUsersCount}
              ></DeletedUsersPreview>
              .
            </p>
            <p>
              Going forward, all new people added to this slack workspace will
              immediately have accounts created for them on Luna Park and
              receive a welcome email. All people removed from this slack
              workspace will have their Luna Park access revoked.
            </p>
          </section>

          <section className='w-full mt-6'>
            <h3 className='font-bold'>Send Email Invite?</h3>
            <label
              htmlFor='sendInviteEmails'
              className='w-full mt-1 border border-secondary rounded-xl p-2.5 flex items-center gap-2.5 cursor-pointer'
            >
              <input
                type='checkbox'
                id='sendInviteEmails'
                className='w-5 h-5 checkbox-dark'
                checked={sendInviteEmails}
                onChange={(e) => {
                  setSendInviteEmails(e.target.checked);
                }}
              />
              <p>
                Checking this box will send an email invite to all{' '}
                <span className=' text-tertiary'>{data.addUsersCount}</span> new
                account {pluralize('recipient', data.addUsersCount)}
              </p>
            </label>
          </section>
        </main>

        <footer className='mt-7.5 w-full flex justify-center items-center gap-5'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 h-10'
            onClick={() => props.onComplete(sendInviteEmails)}
          >
            Confirm
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export interface triggerSlackAsSourceOfTruthModalProps {
  connection: OrgConnection;
  onConfirm?: () => void;
}

export function useTriggerSlackAsSourceOfTruthModal(): (
  props: triggerSlackAsSourceOfTruthModalProps
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: triggerSlackAsSourceOfTruthModalProps) => {
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <OpenAsSourceOfTruthModal
            connection={props.connection}
            onClose={p.internalOnCancel}
            onComplete={async (sendInviteEmails: boolean) => {
              p.internalOnConfirm();
              await apiService.organization.updateExConnectionAsSourceOfTruth(
                props.connection.orgId,
                {
                  asSourceOfTruth: true,
                  sendInviteEmails,
                }
              );
              if (props.onConfirm) props.onConfirm();
            }}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}

export type triggerSlackNotAsSourceOfTruthModalProps =
  triggerSlackAsSourceOfTruthModalProps;

export function useTriggerSlackNotAsSourceOfTruthModal(): (
  props: triggerSlackNotAsSourceOfTruthModalProps
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: triggerSlackNotAsSourceOfTruthModalProps) => {
      const res = await triggerFullScreenModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5 py-2'>
            <ConfirmCancelModalHeading>
              Are you sure you no longer want Slack to be the source of truth?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
              Adding and removing members will no longer be automated.
            </ConfirmCancelModalText>
          </div>
        ),
        confirmBtnLabel: 'Confirm',
        confirmBtnVariant: 'warning',
        cancelBtnLabel: 'Cancel',
      });
      if (res.result === 'canceled') return;

      await apiService.organization.updateExConnectionAsSourceOfTruth(
        props.connection.orgId,
        {
          asSourceOfTruth: false,
        }
      );
      if (props.onConfirm) props.onConfirm();
    },
    [triggerFullScreenModal]
  );
}
